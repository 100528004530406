import React from 'react'

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div>
          © 2020 Carrick
        </div>
        <div>
          Have feedback? <a href="https://github.com/josephwegner/carrick-issues-tracker/issues/new">Create an issue</a> or <a href="mailto:carrick@joewegner.com">reach out</a>.
        </div>
        <div>
          <a href="/terms">Terms of Service</a> &#183; <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
    )
  }
}